<template>
  <div class="min-h-screen flex flex-col">
    <!-- Header -->
    <div class="flex justify-between items-center px-2 mb-10">
      <div
        id="date"
        class=" font-EffraR tetx-lg uppercase bg-rosDok-ultra text-white px-3 py-1 rounded-50"
      >
        {{ _moment()(today).format("dddd DD MMMM YYYY") }}
      </div>
      <div class="flex">
        <button
          class="bg-dokBlue-ultra text-white py-2 rounded-l border-0 border-r border-solid border-dokBlue-light px-3 cursor-pointer"
          @click="onChangeDate('previous')"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="bg-dokBlue-ultra text-white py-2 rounded-r border-0 border-l border-solid border-dokBlue-light px-3 cursor-pointer"
          @click="onChangeDate('next')"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <!-- Angenda Layouts -->
    <div
      class="flex flex-col mb-0 p-0"
      v-for="(hour, indexHour) in getDayHours()"
      :key="indexHour"
    >
      <div class="flex gap-x-0 px-2">
        <div
          class="w-[50px] border-0 border-r border-solid border-gray-400 px-1 h-100 relative"
        >
          <div class="font-EffraM h-full absolute-top-2">
            {{ hour }}
          </div>
        </div>
        <div
          class="w-full h-100 border-0 border-b border-solid border-gray-400 relative overflow-hidden"
          id="borderBottom"
        >
          <itemEvent
            class="flex items-start justify-between w-full px-1"
            v-for="eventItem in eventsDay(
              `${_moment()(today).format('DD/MM/YYYY')}`,
              hour
            )"
            :key="eventItem.id"
            :event="eventItem"
          />
          <div
            v-if="
              !blockedHours(
                `${_moment()(today).format('DD/MM/YYYY')} ${hour}`,
                _moment()(today).weekday()
              ).length
            "
            class="bg-isblock bg-opacity-30 static h-full w-full"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { EventBus } from "@/event-bus";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { find, filter, map } from "lodash";

// Components
const itemEvent = () => import("./items/index");

export default {
  props: {
    events: Array,
    nowDate: Object
  },
  components: { itemEvent },
  computed: {
    ...mapGetters({
      slots: "agenda/slots"
    }),
    today: {
      get() {
        return this.nowDate;
      },
      set() {
        return; //console.log("CHANGE DATE");
      }
    }
  },
  methods: {
    getDayHours() {
      let items = [];
      new Array(16).fill().forEach((acc, index) => {
        items.push(moment({ hour: index + 8 }).format("HH:mm"));
      });
      return items;
    },
    _moment() {
      return moment;
    },
    blockedHours(date, indexDay) {
      let formatDateToMoment = moment(date, "DD/MM/YYYY HH:mm");
      let slotsByIndexDay = find(this.slots, slot => slot.day === indexDay + 1);

      if (!slotsByIndexDay) return false;

      let isBlocked = filter(slotsByIndexDay.slots, slot => {
        const from = moment(slot.from, "HH:mm"),
          to = moment(slot.to, "HH:mm");

        if (formatDateToMoment.isBefore(new Date(), "day")) return;

        return (
          moment(formatDateToMoment.format("HH:mm"), "HH:mm").isBetween(
            from,
            to
          ) || moment(formatDateToMoment.format("HH:mm"), "HH:mm").isSame(from)
        );
      });

      return isBlocked;
    },
    eventsDay(day, hour) {
      let dateDay = moment(`${day} ${hour}`, "DD/MM/YYYY HH:mm");

      let filterEvents = [];

      map(this.events, event => {
        let dateEvent = moment(event.startAt);

        if (
          dateEvent.isSame(dateDay, "day") &&
          dateEvent.format("HH") === dateDay.format("HH")
        ) {
          filterEvents.push(event);
        }
      });

      return filterEvents;
    },
    onChangeDate(type) {
      if (type === "previous") {
        // previous Day
        return EventBus.$emit("Agenda_Mobile_Day", { action: "previous" });
      }

      if (type === "next") {
        // Next Day
        return EventBus.$emit("Agenda_Mobile_Day", { action: "next" });
      }
    }
  }
};
</script>
